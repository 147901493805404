// 系统管理
import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 用户分页列表
export const queryUserPage = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/userorg/userpage',
    params,
  })
}

// 添加用户
export const queryUserAdd = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/add',
    params,
  })
}

// 删除用户
export const queryUserDelete = async (params: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/delete',
    params,
  })
}

// 修改用户
export const queryUserUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.USER,
    url: '/user/update',
    params,
  })
}

// 查询用户组织
export const queryUserOrg = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/userorg/query',
    params,
  })
}

// 查询角色下拉列表
export const queryRoleList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/role/treelist',
    params,
  })
}

// 查询用户详情
export const queryUserDetail = async (params: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/user/detail',
    params,
  })
}

// 查询用户权限
export const queryUsersCurrentFull = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/users/currentFull',
    params,
  })
}

// 数据字典-卫星分页
export const querySatellitePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/page/query',
    params,
  })
}

// 数据字典-添加卫星资源
export const satelliteInfoAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/add',
    params,
  })
}

// 数据字典-删除卫星资源
export const satelliteInfoDelete = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/delete',
    params,
  })
}

// 数据字典-编辑卫星资源
export const satelliteInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/update',
    params,
  })
}

// 数据字典-卫星下拉列表
export const querySatelliteTreeList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/treeList/query',
    params,
  })
}

// 数据字典-获取两行根通过卫星编号
export const querySatelliteTle = async (params: any) => {
  return get({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/tle/get',
    params,
  })
}

// 卫星参数导出
export const exportSatelliteTle = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/dic/satellite/tle/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 查询应用所有资源
export const queryAppList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/application/manager/list/query',
    params,
  })
}

// 查询资源列表
export const queryAppResourceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/application/manager/resource/query',
    params,
  })
}

// 修改应用资源
export const updateApplicationInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/application/manager/info/update',
    params,
  })
}

export const queryRoleTreeList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.USER,
    url: '/role/treelist/curorg',
    params,
  })
}

// 获取设备卡号列表
export const queryDeviceBindCardPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/page/query',
    params,
  })
}

// 添加设备卡号
export const deviceBindCardInfoAdd = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/info/add',
    params,
  })
}

// 修改设备卡号
export const deviceBindCardInfoUpdate = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/info/update',
    params,
  })
}

// 获取单模设备列表
export const querySingleDeviceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/single/device/list',
    params,
  })
}

// 删除设备卡号
export const deviceBindCardInfoDelete = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/info/delete',
    params,
  })
}

//日志文件-设备下拉列表
export const queryTerminalLogDevice = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/terminal/log/tree/query',
    params,
  })
}

// 终端日志列表
export const queryTerminalLogPage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/terminal/log/page/query',
    params,
  })
}

//终端日志下载
export const downloadTerminalLog = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/terminal/log/download',
    params,
    // userConfig: {
    //   responseType: 'arraybuffer',
    //   timeout: 100000,
    // },
  })
}

//4G卡号 导入模板下载
export const downloadDeviceCardTemplate = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/import/template',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 批量导入设备卡号
export const handleUploadDeviceCardTemplate = () => {
  return SUFFIX.DCOP + '/device/bind/card/import'
}

//4G卡号 设备卡号导出
export const exportDeviceCardTable = async (params?: any) => {
  return post({
    suffix: SUFFIX.DCOP,
    url: '/device/bind/card/export',
    params,
    userConfig: {
      responseType: 'arraybuffer',
      timeout: 100000,
    },
  })
}

// 固件升级列表
export const queryFirmwarePage = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/firmware/manage/page',
    params,
  })
}

// 添加固件
export const addFirmwareInfo = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.DCOP,
    url: '/firmware/manage/add',
    params,
  })
}

// 删除固件
export const deleteFirmwareInfo = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/firmware/manage/delete',
    params,
  })
}

// 获取固件网关信息
export const queryFirmwareGatewayList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.DCOP,
    url: '/firmware/manage/gatewayList/query',
    params,
  })
}

// 固件上传
export const handleUploadFirmware = () => {
  return SUFFIX.DCOP + '/firmware/manage/upload'
}
