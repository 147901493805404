import { createRouter, createWebHashHistory } from 'vue-router'

export const routes: any = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
    },
    component: () => import('@/views/home/home.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: false,
    },
    component: () => import('@/views/login/login.vue'),
  },
  {
    path: '/device',
    name: 'device',
    meta: {
      title: '设备管理',
    },
    redirect: '/device/device-list',
    children: [
      {
        path: 'device-list',
        name: 'device-list',
        meta: {
          title: '设备列表',
          banner:
            '可管理设备，可配置设备的参数，可关联子设备。可对设备进行流转，支持批量流转。',
        },
        component: () => import('@/views/device/index.vue'),
        children: [
          {
            path: 'device-detail',
            name: 'device-detail',
            meta: {
              title: '添加设备',
              banner: '',
            },
            component: () => import('@/views/device/detail/detail.vue'),
          },
          {
            path: 'device-data',
            name: 'device-data',
            meta: {
              title: '设备详情',
              banner:
                '可查询设备的详细信息，包括设备基础信息，设备地图，设备属性，关联子设备，设备告警记录，设备发货记录',
            },
            component: () => import('@/views/device/data/index.vue'),
          },
        ],
      },
      {
        path: 'device-location',
        name: 'device-location',
        meta: {
          title: '设备分布',
          banner: '',
        },
        component: () => import('@/views/device/location/index.vue'),
      },
      {
        path: 'device-station',
        name: 'device-station',
        meta: {
          title: '地面站管理',
          banner:
            '可管理地面站，可预测卫星过境地面站的时间，支持过去和未来时间的预测。',
        },
        component: () => import('@/views/device/station/index.vue'),
      },
    ],
  },
  {
    path: '/tenant',
    name: 'tenant',
    meta: {
      title: '租户管理',
    },
    redirect: '/tenant/tenant-list',
    children: [
      {
        path: 'tenant-list',
        name: 'tenant-list',
        redirect: '/tenant/tenant-list',

        meta: {
          title: '租户列表',
          banner: '可管理租户，可开通套餐或对套餐续费。',
        },
        component: () => import('@/views/tenant/index.vue'),
        children: [
          {
            path: '',
            name: 'tenant-list-index',
            meta: {
              title: '租户列表',
              banner: '可管理租户，可开通套餐或对套餐续费。',
            },
            component: () => import('@/views/tenant/list/tenant.vue'),
          },
          {
            path: 'tenant-detail',
            name: 'tenant-detail',
            meta: {
              title: '租户详情',
              banner:
                '可查询租户的详细信息，包括基础信息，套餐信息，认证信息，资源详情、订单记录。',
            },
            component: () => import('@/views/tenant/detail/detail.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '产品管理',
    },
    redirect: '/product/product-list',
    children: [
      {
        path: 'product-list',
        name: 'product-list',
        meta: {
          title: '产品列表',
          banner: '可管理产品，可关联产品模板。产品包括网关和传感器。',
        },
        component: () => import('@/views/product/index.vue'),
        children: [
          {
            path: '',
            name: 'product-index',
            meta: {
              title: '产品列表',
              banner: '可管理产品，可关联产品模板。产品包括网关和传感器。',
            },
            component: () => import('@/views/product/list/list.vue'),
          },
        ],
      },
      {
        path: 'template',
        name: 'template',
        meta: {
          title: '产品模板',
          banner: '可管理产品模板，可关联物模型。',
        },
        redirect: '/product/template',
        component: () => import('@/views/product/index.vue'),
        children: [
          {
            path: '',
            name: 'template-index',
            meta: {
              title: '产品模板',
              banner: '可管理产品模板，可关联物模型。',
            },
            component: () => import('@/views/product/template/index.vue'),
          },
          {
            path: 'model-detail',
            name: 'model-detail',
            meta: {
              title: '物模型管理',
              banner: '可管理物模型，物模型包括属性、方法和事件三种功能类型。',
            },
            component: () => import('@/views/product/model/index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/package',
    name: 'package',
    meta: {
      title: '营销管理',
    },
    redirect: '/package/package-list',
    children: [
      {
        path: 'package-list',
        name: 'package-list',
        meta: {
          title: '套餐管理',
          banner:
            '可管理套餐，可对套餐进行发布、撤销发布，发布后的套餐才能使用。',
        },
        redirect: '/package/package-list',
        component: () => import('@/views/package/index.vue'),
        children: [
          {
            path: '',
            name: 'package-index',
            meta: {
              title: '套餐管理',
              banner:
                '可管理套餐，可对套餐进行发布、撤销发布，发布后的套餐才能使用。',
            },
            component: () => import('@/views/package/list/list.vue'),
          },
          {
            path: 'package-detail',
            name: 'package-detail',
            meta: {
              title: '套餐详情',
              banner: '可查看套餐的详细信息，包括基础信息、资源详情。',
            },
            component: () => import('@/views/package/detail/index.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/alarm',
    name: 'alarm',
    meta: {
      title: '告警中心',
    },
    redirect: '/alarm/alarm-list',
    children: [
      {
        path: 'alarm-list',
        name: 'alarm-list',
        meta: {
          title: '告警记录',
          banner:
            '可查询告警信息，可根据租户、设备名称、告警名称、告警级别、告警类型、告警时间进行筛选。',
        },
        component: () => import('@/views/alarm/index.vue'),
      },
    ],
  },

  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      title: '数据中心',
    },
    redirect: '/statistics/report',
    children: [
      // {
      //   path: 'statistics-history',
      //   name: 'statistics-history',
      //   meta: {
      //     title: '历史数据',
      //     banner:
      //       '可查询设备的历史数据，支持按时间段查询数据的历史趋势图，支持数据导出。',
      //   },
      //   component: () => import('@/views/statistics/history/index.vue'),
      // },
      {
        path: 'report',
        name: 'report',
        meta: {
          title: '报表查询',
          banner: '可查询报表，支持历史趋势图和导出',
        },
        component: () => import('@/views/statistics/report/index.vue'),
      },
      {
        path: 'report-config',
        name: 'report-config',
        meta: {
          title: '报表配置',
          banner: '可管理运营报表配置参数，支持关闭和开启',
        },
        component: () => import('@/views/statistics/report-config/index.vue'),
      },
      {
        path: 'videos',
        name: 'videos',
        meta: {
          title: '视频监控',
          banner: '可查看视频监控画面，支持分屏显示和全屏显示',
        },
        component: () => import('@/views/statistics/videos/index.vue'),
      },
      {
        path: 'files',
        name: 'files',
        meta: {
          title: '文件查询',
          banner: '可收取文件数据，支持预览和下载',
        },
        component: () => import('@/views/statistics/file/index.vue'),
      },
    ],
  },
  {
    path: '/config',
    name: 'config',
    meta: {
      title: '系统管理',
    },
    redirect: '/config/user-list',
    children: [
      {
        path: 'user-list',
        name: 'user-list',
        meta: {
          title: '用户管理',
          banner: '可管理用户，支持关联角色',
        },
        component: () => import('@/views/config/user/user.vue'),
      },
      {
        path: 'app',
        name: 'app',
        meta: {
          title: '应用管理',
          banner: '可管理应用的资源，可关联功能。',
        },
        component: () => import('@/views/config/app/index.vue'),
      },
      {
        path: 'log-list',
        name: 'log-list',
        meta: {
          title: '诊断日志',
          banner:
            '可查询诊断日志，可根据租户、设备名称、日志ID、日志类型、卫星ID、采集时间和上报时间查询，支持数据导出。',
        },
        component: () => import('@/views/config/msg/log.vue'),
      },
      {
        path: 'log-file',
        name: 'log-file',
        meta: {
          title: '设备日志',
          banner: '可查询设备日志，支持文件导出。',
        },
        component: () => import('@/views/config/log-file/index.vue'),
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        meta: {
          title: '数据字典',
        },
        component: () => import('@/views/config/dictionary/index.vue'),
      },
      {
        path: 'firmware',
        name: 'firmware',
        meta: {
          title: '升级管理',
          banner: '可管理设备升级固件包。',
        },
        component: () => import('@/views/config/firmware/index.vue'),
      },
    ],
  },
  {
    path: '/data-application',
    name: 'data-application',
    meta: {
      title: '数据应用',
    },
    redirect: '/data-application/export',
    children: [
      {
        path: 'export',
        name: 'export',
        meta: {
          title: '数据导出',
          banner:
            '可查询诊断日志，可根据租户、设备名称、日志ID、日志类型、卫星ID、采集时间和上报时间查询，支持数据导出。',
        },
        component: () => import('@/views/data-application/export/index.vue'),
      },
    ],
  },
]

// 给meta赋值
routes.forEach((element: any) => {
  element.meta = {
    ...element.meta,
    layout: !(element?.meta?.layout === false),
  }
})

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
